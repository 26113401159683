import {
  Component,
  OnInit,
  DoBootstrap,
  ElementRef,
  Inject
} from '@angular/core';

import { LazyLoaderService } from './lazy-loader.service';
import { EnvService } from './shared/env.service';
import { TranslateService } from '@ngx-translate/core';
import { getLanguage } from '../translations/pure';

@Component({
  selector: 'polylearn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'landing';

  constructor(
    private lazyLoader: LazyLoaderService,
    private elRef: ElementRef,
    public envService: EnvService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.lazyLoader.load(this.elRef.nativeElement);
    // this.translate.setDefaultLang(getLanguage());
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(getLanguage());
  }
}
