import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EnvService {
  env: any;

  constructor() {
    this.env = environment;
  }
}
