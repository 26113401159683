export const translations = {
  menu: {
    my_account: 'My account',
    switch: 'Switch to',
    logout: 'Logout'
  },
  home: {
    my_profile: 'My profile',
    pseudo: 'Your username',
    timing_training: 'Practice hours',
    timing_training_on_platform: 'Practice hours on the platform',
    level: 'Level:',
    switch_level: 'Change level',
    folder: 'About',
    all_articles: 'All articles'
  },
  profile_exam: {
    dashboard: 'Dashboard',
    my_dashboard: 'My dashboard',
    dashboard_en: 'Dashboard',
    target_level: 'Objective',
    target_level_explaination:
      'This is the objective you have set for yourself.',
    current_level: 'Current level',
    current_level_explaination:
      'Our estimation is based on the latest information.',
    good_answers: 'Correct answers',
    good_answers_explaination:
      'Percentage of correct answers based on the last 100 questions',
    last_test: 'Last mock exam',
    last_test_toeic: 'Last TOEIC mock exam',
    last_test_delf: 'Last DELF mock exam',
    last_test_explaination: 'This is the score of your last mock exam.',
    start_training: 'Start my practice',
    improve_concentration: 'Improve my concentration',
    do_mock: 'Take mock exams',
    exercise_stats: 'Track your progress',
    skills_stats: 'Skills statistics',
    train: 'Practice',
    my_improvement: 'My progress',
    his_improvement: 'His/Her progress',
    lessons_menu: {
      zero: 'What I can do',
      one: 'Recently acquired lesson',
      others: 'Recently acquired lessons'
    },
    lessons_menu_worst: {
      zero: 'Congratulations, you have mastered all the lessons!',
      one: 'Just one more lesson, keep going!',
      others: 'Language Assistant: lessons to work on'
    }
  },
  training_home: {
    personalised: 'Customized',
    personalised_training: 'Customized practice',
    random_training: 'Practice randomly',
    estimated_score: 'Estimated score:',
    good_answers_percentage: '% of correct answers',
    legend: 'Legend',
    objective_reached: 'Goal achieved',
    objective_not_reached: 'Goal not achieved',
    you_mastered_this_module: 'You have mastered this part',
    you_didnt_mastered_this_module: 'Keep practicing to reach your goal',
    your_objective: 'Your goal:',
    update_your_objective: 'Modify your goal',
    start_mock_exam: 'Start a mock exam',
    resume_mock_exam: 'Continue the mock exam',
    finish_mock_exam: 'Finish the mock exam',
    empty_historic: "You haven't finished any mock exam yet"
  },
  exam_home: {
    mock_exam: 'Mock exam',
    historic: 'History',
    continue: 'Resume',
    start: 'Start',
    oral_comprehension: 'Listening comprehension:',
    written_comprehension: 'Reading comprehension:',
    oral_expression: 'Speaking:',
    written_expression: 'Writing:',
    result: 'Score:',
    loading_data: 'Loading data',
    pause: 'Paused',
    put_in_pause: 'Pause',
    resume: 'Resume',
    cannot_pause: 'On the day of the exam, you will not be able to pause.',
    end_mock_test: 'Finish the exam',
    back_to_historic: 'Back to my history'
  },
  lessons_home: {
    focus_language: 'Language Assistant',
    description:
      'The lessons to know to succeed in your French language certification.',
    lessons: 'Lessons',
    you_master_this_lesson: 'You have mastered this lesson at',
    search_placeholder: 'Search for a lesson',
    lessons_detail: 'Lesson details',
    last_connexion: 'Last connection:',
    estimated_time: 'Estimated time on the platform:',
    grid: {
      mastered: 'Mastered',
      not_mastered: 'Not mastered',
      difficulties: 'Difficulties',
      wip: 'In practice (default)'
    }
  },
  lesson_detail: {
    my_score: 'My score',
    no_score:
      'Do you remember everything? Evaluate yourself on this lesson and assess your level',
    exercise: 'Exercise',
    you_master_score:
      'I have mastered <b>{{score | toPercent }}%</b> of this lesson.'
  },
  blog: {
    read_more_articles: 'See more articles'
  },
  training_detail: {
    choose_training_module: 'Select your practice',
    validate: 'Submit',
    close: 'Close',
    help: 'Instructions',
    understood: 'I understand',
    no_more_showing: 'Do not show again',
    my_score: 'My score:',
    no_train_on_lesson: 'Stop practicing on this lesson',
    the_instructions: 'Instructions',
    submit_answers: 'I submit my answers',
    ask_error: 'Why do you think there is an error?',
    signal_error: 'Report an error',
    thanks_for_help: 'Thank you for your help!',
    register: 'Sign up'
  },
  correction_home: {
    exercises_to_correct: 'Exercises to be marked',
    not_attached_to_learners: 'You are not associated with learners.',
    not_attached_to_teacher: 'You are not associated with a teacher.'
  },
  navbar: {
    training: 'Practice',
    exam: 'Exam',
    home: 'Home',
    correction: 'Correction',
    focus_langue: 'Language Assistant',
    back: 'Back',
    learners: 'Students',
    trainers: 'Teachers',
    global_groups: 'Global groups',
    groups: 'Groups',
    sub_organizations: 'Sub organizations',
    mock_exams: 'Mock exams',
    exercises_to_correct: 'Ex. to be marked'
  },
  manager: {
    home: {
      space: 'Space',
      sub_title: 'Welcome to your management space',
      learners_numbers: 'Number of students',
      trainers_numbers: 'Number of teachers',
      manage: 'Manage',
      question: 'Any questions?',
      contact_us: 'Contact us',
      still_credits: 'You have {{credits}} licenses'
    }
  },
  users_list: {
    add_teacher: 'Add a teacher',
    add_student: 'Add a learner',
    students_list: 'List of students',
    teachers_list: 'List of teachers',
    manager: {
      teacher_description:
        'Create/Delete teachers. Creating teacher accounts is free',
      student_description: 'Create/Delete students'
    },
    teacher: {
      description: 'Track the progress of your students'
    },
    table: {
      first_name: 'First name',
      last_name: 'Last name',
      email: 'Email',
      actions: 'Actions',
      target_score: 'Target score',
      administrator: 'Administrator',
      estimated_score: 'Estimated score',
      level: 'Level',
      timing_training: 'Practice time',
      expiration_date: 'Expiration date'
    },
    actions: {
      see_profile: 'See the learner’s profile',
      see_groups: 'See the learner’s groups',
      download_pdf_profile: 'Download the learner’s profile (PDF)',
      send_back_login: 'Resend login details',
      extend_account: 'Extending the duration of the account',
      delete_account: 'Delete the account',
      switch_level: 'Change the level of the account',
      remove_from_manager: 'Remove from administrators',
      add_to_manager: 'Grant administrator rights'
    }
  },
  files: {
    download_csv: 'Download data (CSV)'
  },
  search: 'Search',
  groups: {
    limited: 'Restricted:',
    list: 'List of groups',
    add_bulk_students: 'Add a batch of students',
    created_by_admin: 'Created by an administrator',
    delete_group: 'Delete group',
    manager: {
      description:
        'View/Manage your global groups. Global groups are visible to your teachers but they cannot modify them.',
      create_group: 'Make a global group'
    },
    teacher: {
      description: 'View/Manage your groups',
      create_group: 'Make a group',
      group_name: 'Group name',
      restrain: 'Restrict access to this group (optional)',
      cancel: 'Cancel',
      create: 'Create'
    }
  },
  institutions: {
    title: 'List of sub organizations',
    organization: 'Organization',
    list: {
      add: 'Create a sub-organization',
      logo: 'Logo',
      name: 'Name',
      still_credits: 'Remaining licenses',
      total_credits: 'Total licenses',
      used_credits: 'Licenses used',
      actions: 'Actions',
      organisation_edit: 'Edit organization',
      organisation_name: 'Organization name',
      logo_url: 'Logo URL',
      slug: 'Slug for custom login url',
      generate: 'Generate from name',
      close: 'Close',
      validate: 'Submit',
      watch_organization: 'View organization',
      see_credits: 'See credits'
    }
  },
  exercises_chart: {
    empty: 'Practice to get your statistics',
    train: 'Practice'
  },
  lessons_toolbar: {
    unknown: {
      zero: 'You have mastered all the lessons!',
      one: 'You have one lesson left to master',
      others: 'Lessons not yet studied: {{unknown.length}}'
    },
    known: {
      zero: 'No lesson acquired',
      one: 'One acquired lesson',
      others: 'Acquired lessons: {{known.length}}'
    }
  },
  account: {
    password: 'Password',
    email: 'Email',
    billing: 'Billing',
    datas: 'Data',
    password_component: {
      change_your_password: 'Change your password',
      old_password: 'Old password',
      new_password: 'New password',
      new_password2: 'Confirm new password',
      submit: 'Submit'
    },
    email_component: {
      change_your_email: 'Change your email',
      new_email: 'New email',
      new_email2: 'Confirm new email',
      submit: 'Submit'
    },
    billing_component: {
      bills: 'Invoices',
      empty: "You don't have any invoice yet.",
      see_our_offers: 'See our offers',
      download: 'Download'
    },
    remove_account_component: {
      title: 'Delete my account',
      submit: 'Delete my account',
      remove_account_text:
        'If you want to delete all the data from your account and your account itself, just click on the button <b>Delete my account</b>. <i>No recourse will be possible.</i>'
    }
  },
  dialog_lessons: {
    close: 'Close',
    to_train: 'Train me',
    score: 'Score:'
  },
  part_training: {
    parts: {
      zero: 'All modules',
      one: 'Module: ',
      others: 'Modules: '
    }
  },
  exercises: {
    question: 'Question',
    dont_know: "I don't know the answer",
    answer_submit: 'I submit my answer',
    sent_for_correction_to_teacher: 'Send for correction to the teacher',
    good_answer: 'Correct answer',
    no_answer: 'No answer.'
  },
  record_audio: {
    push_the_button: 'Press the button below to start recording.',
    push_the_button_to_stop: 'Press the button below to stop recording.',
    your_recording: 'Your recording',
    max_duration: 'Recording duration of maximum {{ maxTime }} minutes.',
    only_one_try: 'You have only one attempt for registration.',
    record: 'Save',
    stop_recording: 'Stop recording',
    no_more_try: 'You can no longer record',
    cancel_recording: 'Cancel recording',
    allow_mic: 'Please allow access to your microphone'
  },
  timer: {
    title: 'Time elapsed',
    content: 'On the day of the exam, you will not be allowed more time.'
  },
  manual_correction: {
    corrected: 'Marked',
    to_correct: 'To be marked',
    not_corrected: 'Not marked',
    see_exercise: 'View exercise',
    to_correct2: 'Correct',
    still_not_corrected: 'Not yet corrected',
    level: 'Level',
    yes: 'Yes',
    no: 'No',
    correction_date: 'Correction date',
    result: 'Score',
    date: 'Date',
    mock_exam: 'Mock exam',
    learner: 'Learner',
    type: 'Category',
    actions: 'Actions',
    empty: 'No data to display',
    exercise: 'Exercise',
    my_answer: 'My answer',
    user_answer: "Learner's answer",
    suggested_correction: 'Suggested answer',
    teacher_correction: "teacher's correction",
    score: 'Score:',
    not_corrected_3: 'You have not been corrected yet.',
    feedback: 'Feedback',
    score_2: 'Grade',
    close: 'Close',
    validate: 'Submit',
    correction: 'Correction'
  },
  toasts: {
    offline: 'It seems that you are offline',
    expired_token: 'Your session has expired',
    logout: 'You have been successfully logged out',
    incorrect_password: 'Incorrect password',
    ask_end_test: {
      title: 'Are you sure you want to finish this exam?',
      description: 'You will not be able to take it back afterwards.',
      ok: 'Finish',
      cancel: 'Cancel'
    },
    ask_end_test_confirmation: 'The exam has been successfully completed.',
    sent_to_teacher: 'Answer sent to teacher for correction',
    modifications_updated: 'Changes have been successfully made.'
  },
  teacher_home: {
    title: 'Teacher area',
    description: 'Welcome to your teacher area',
    filter_group: 'Filter by group',
    remove_filter: 'Remove filter',
    platform_usage: 'Platform usage',
    general_level: 'Global level',
    mean_score_lessons: 'Mean score of the lessons',
    used_account: 'Used accounts',
    unused_account: 'Unused accounts'
  },
  teacher_exam_home: {
    list: 'List',
    empty: "You don't have any mock exam yet",
    results: 'Scores',
    download_csv: 'Download scores (CSV)',
    title_delf: 'DELF mock exams',
    description_delf:
      'You can create mock exams for your students. You can also see the scores of the mock exams that your students have taken.',
    action_delf: 'Set a DELF mock exam up'
  },
  footer_link: {
    contact_us: 'Contact us',
    credits: 'Credits',
    cgu: 'Terms'
  },
  logoInstitution: {
    text: 'Your account is managed by'
  },
  institutions_list: {
    dialog: {
      create: 'Create an organization',
      edit: 'Edit an organization',
      created: 'The organization has been created',
      saved: 'The organization has been saved',
      credits_usage: 'Credits usage'
    }
  },
  help: {
    title: 'Help',
    home_delf:
      'On your home page, you will find your dashboard, which allows you to view and edit your goal, and to know your current level. As you progress, the latest acquired lessons appear, and lessons that are challenging for you are suggested. A diagram lets you know which parts of the exam you are having difficulty with.<br />\nOn this page, there is also information related to your account, including the option to extend your subscription, and the latest articles on our blog.<br />\nThis button at the top right allows you to change your password or log out.<br />\nTo modify your goal, click on "Goal", drag the slider to the desired score, and then click on "Submit".<br />\nTo view one of the suggested lessons, simply click on it. The window that appears also shows your score and allows you to practice on this particular lesson.<br />',
    exam_delf:
      "The 'Exam' tab allows you to take mock exams to test your level. <br />\n<br />\nTo know your level, we strongly advise you to take the exam under exam conditions. Allow 2 hours for the written part and 1 hour 20 minutes for the oral part, without interruption.<br />\nListening comprehension: 2 listenings for Exercises 1 and 2, only 1 listening for Exercise 3<br />\nReading comprehension: you have 1 hour<br />\nWritten production: you have 1 hour<br />\nOral production: 30 minutes of preparation, a maximum of 20 minutes of recording<br />\n<br />\nThe orange button and the 'Start/Resume' button allow you to start a mock exam or resume an ongoing mock exam. A chart allows you to see your progress. Finally, your history allows you to access your previous answers as well as the correction of all the exams you have taken.<br />\nDuring a mock exam, you can move around as you please using the tabs at the top left: when you return, you will pick up where you left off. You can interrupt an exam as many times as you want and resume it whenever you wish. The 'Autoplay' button at the top right allows you to activate or deactivate automatic advancement to the next question when you have answered all the questions on a page. In any case, you can always go back to previous questions or move on to the next ones using the arrows.<br />\nDo you want to test yourself on a DELF mock exam but don't have 3 hours and 20 minutes in a row? <br />\nOur platform allows you to start with any part (Listening Comprehension, Reading Comprehension, Written Production, Oral Production). You can also take the exam in several sessions and resume where you left off.<br />\nTip: Always answer the question you don't know the answer to; you can't lose points.<br />",
    training_delf:
      "The 'Training' tab shows your progress by exam section: the percentages of correct answers and the red or green lines in the center let you know where you are having difficulties.<br />\nBy clicking on 'randomly train on the modules', questions from all modules are asked at random.<br />\nIt is also possible to train by skill module.<br />\n<br />\nOnce the training is launched, the questions start.<br />\n<br />\nAt the top left:<br />\n<br />\n- The 'all modules' button gives you the possibility to choose the modules on which you wish to be questioned<br />\nAt the top right:<br />\n- The blue indication gives you an overall view of your progress<br />\n- The 'instructions' button gives you the instruction for the section you are being questioned on. <br />"
  }
};
