<a *ngIf="isIndex && announcement"
 [innerHTML]="announcement.html"
 [href]="announcement.link"
 class="announcement text-center new-toeic"
 fxLayout="row" fxLayoutAlign="center center">
</a>


<div class=""></div>


<header [class]="{ 'isIndex': isIndex, 'primary-bg': !isIndex, 'hasAnnouncement': announcement}">

  <div class="content">
    <div class="container">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div>
          <a routerLink="/" class="image">
            <img *ngIf="(exam | lowercase) === 'bright'" src="/ng1/www/img/new/header/nathan-bright.png">
            <img *ngIf="(exam | lowercase) === 'toeic'" src="/ng1/www/img/new/header/nathan-toeic.svg" style="width: 235px;">
            <img height="45" *ngIf="(exam | lowercase) === 'lerobert'" src="/assets/lerobert/img/le_robert_w.png">
            <img height="45" *ngIf="(exam | lowercase) === 'delf'" src="/assets/delf/img/abc-delf_logo3.svg">
            <img height="45" *ngIf="(exam | lowercase) === 'linguaskill'" src="/ng1/www/img/new/header/nathan-linguaskill.png">
          </a>
        </div>

        <div fxFlex></div>

        <div fxLayout="row" fxHide.xs fxHide.sm fxLayoutGap="32px" fxLayoutAlign="center center">
          <div *ngIf="!isLogin" class="links" fxHide.xs fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="32px">
            <div class="link" *ngFor="let link of displayLinks; let last = last">
              <ng-container *ngIf="link.link === 'contact'">
                <a mat-button class="primary-bg" mat-raised-button style="color: white;" [routerLink]="link.link">
                  {{link.text}}
                </a>
              </ng-container>

              <ng-container *ngIf="link.link !== 'contact'">
                <a *ngIf="link.link.startsWith('http')" target="_blank" class="direct" [href]="link.link">
                  {{link.text}}
                </a>

                <a *ngIf="!link.link.startsWith('http')" class="direct" [routerLink]="link.link" fxLayout="row" fxLayoutAlign="center center">
                  <span>{{link.text}}</span> <mat-icon *ngIf="link.children">keyboard_arrow_down</mat-icon>
                </a>

                <!--
                  <a *ngIf="!link.link.startsWith('http') && last" mat-raised-button color="warn" [routerLink]="link.link">
                    {{link.text}}
                  </a>
                -->

                <div *ngIf="link.children" class="overlay-nav-container">
                  <div class="overlay-nav">
                    <a *ngFor="let l of link.children" [routerLink]="l.link" fxLayout="row" fxLayoutAlign="center center">
                      {{l.text}}
                    </a>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

        </div>

        <div fxFlex></div>

        <div class="actions">

          <div fxHide.xs fxHide.sm fxLayout="row" fxLayoutGap="8px">
            <div class="link" *ngFor="let link of actionsLinks; let last = last">
              <a *ngIf="link.link.startsWith('http')" target="_blank" mat-stroked-button [href]="link.link" class="uppercase">
                {{link.text}}
              </a>

              <a *ngIf="!link.link.startsWith('http') && last" mat-stroked-button [routerLink]="link.link" class="uppercase">
                {{link.text}}
              </a>

              <a *ngIf="!link.link.startsWith('http') && !last" mat-button [routerLink]="link.link" class="uppercase">
                {{link.text}}
              </a>
            </div>
          </div>
          <div fxHide.gt-sm class="nav-right">
            <button type="button" fxShow="false" fxShow.xs fxShow.sm mat-icon-button (click)="isMobileMenuOpened = true">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>

</header>



<div *ngIf="isMobileMenuOpened" [@listAnimation]="actionsLinks?.length" class="mobile" fxLayout="column"
     fxLayoutAlign="center center" fxLayoutGap="32px">
  <button (click)="isMobileMenuOpened = false" class="toggle-menu" type="button" mat-icon-button
          aria-label="Toggle menu">
    <mat-icon>close</mat-icon>
  </button>

  <ng-container *ngFor="let route of links">
    <div class="route">

      <a *ngIf="!route.link.startsWith('http')" fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="8px"
          routerLinkActive="active"
          [routerLink]="route.link"
          (click)="isMobileMenuOpened = false"
          [routerLinkActiveOptions]="{ exact: true }">
        {{route.text}}
      </a>

      <a *ngIf="route.link.startsWith('http')" fxLayout="row"
          fxLayoutAlign="center center"
          [href]="[route.link]"
          target="_blank">
        {{route.text}}
      </a>
    </div>
  </ng-container>
</div>
